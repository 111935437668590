import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'querystring';
import { Link } from 'react-router-dom';
import { Button, Select, DatePicker, Badge, Space } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import moment from 'moment';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { jsonc } from 'jsonc';
const { Option } = Select;

const ProductionProcessDayStorekeeper = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [data, setData] = useState();
  const [machines, setMachines] = useState([]);
  const [date, setDate] = useState(undefined);
  const [day, setDay] = useState(new URLSearchParams(window.location.search).get('day'));
  const [machineChange, setMachineChange] = useState();
  const [activeRollsRes, fetchActiveRollsRes] = useAxios('', [], currentuser.data.token, 'get');
  const [ppToday, fetchPPToday] = useAxios('', [], currentuser.data.token, 'get');
  const [machine, setMachine] = useAxios('', [], currentuser.data.token, 'get');
  const [dateChange, setDateChange] = useState();
  const { search } = window.location;
  const query = qs.parse(search.slice(1));

  useEffect(() => {
    setMachine(`${SERVER_URL}/machines/${query.machine}`, []);
    fetchActiveRollsRes(`${SERVER_URL}/rolls-reserved-byStatus`, []);
    fetchPPToday(`${SERVER_URL}/production-processes-oneday/${query.day}`, []);
    setDateChange(query.day);
    returnedData();
  }, [machineChange, dateChange]);

  useEffect(() => {
    filteringDataForStorekeeperView();
  }, [query]);

  const returnedData = async () => {
    const date = moment(query.day);
    const date2 = moment(date._d).add(1, 'days');

    const dataStorekeeper = await Axios.get(
      `${SERVER_URL}/production-process-day-storekeeper?filter=${JSON.stringify({
        startDate: date,
        endDate: date2,
        machine: query.machine,
      })}`,
    );

    setData(dataStorekeeper?.data?.data);
  };

  const dataa = async () => {
    const dataMachines = await Axios.get(`${SERVER_URL}/machines`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    setMachines(dataMachines.data.items);
  };

  const filteringDataForStorekeeperView = () => {
    // activeRollsRes => activeRollsRes.data;
    // ppToday => ppToday.data;
    let allWOToday = [];
    let allRRforToday = [];
    let allMaterialsArr = [];
    let allMaterialsArrFiltered = [];
    let allStoragePositions = [];
    let allStoragePositionsFiltered = [];

    ///Svi work orderi danas
    for (let i = 0; i < ppToday.data.length; i++) {
      const element = ppToday.data[i];
      allWOToday.push(element.workOrder);
    }

    ///sve rezervisane rolne danas
    for (let i = 0; i < allWOToday.length; i++) {
      const workorder = allWOToday[i];

      for (let i = 0; i < activeRollsRes.data.length; i++) {
        const activeRoll = activeRollsRes.data[i];
        if (activeRoll.workOrder?._id === workorder) {
          allRRforToday.push(activeRoll);
        }
      }
    }

    ///uzimanje svih materijala za danas
    for (let i = 0; i < allRRforToday.length; i++) {
      allMaterialsArr.push(allRRforToday[i].material);
    }
    ///pravljenje unique liste tih materijala
    allMaterialsArrFiltered = allMaterialsArr.map((item) => item._id).filter((v, i, a) => a.indexOf(v) === i);
    ///filtranim materijalima umesto id stavi objekat
    for (let i = 0; i < allMaterialsArrFiltered.length; i++) {
      for (let j = 0; j < allMaterialsArr.length; j++) {
        if (allMaterialsArrFiltered[i] === allMaterialsArr[j]._id) {
          allMaterialsArrFiltered[i] = allMaterialsArr[j];
        }
      }
    }

    for (let i = 0; i < allRRforToday.length; i++) {
      const element = allRRforToday[i];
      allStoragePositions.push(element.roll.storagePosition);
    }

    ///pravljenje unique liste tih pozicija
    allStoragePositionsFiltered = allStoragePositions.map((item) => item).filter((v, i, a) => a.indexOf(v) === i);
    ///sortiranje liste
    allStoragePositionsFiltered.sort((a, b) => a.localeCompare(b, 'en', { numeric: true }));

    for (let i = 0; i < allStoragePositionsFiltered.length; i++) {
      const storageName = allStoragePositionsFiltered[i];

      allStoragePositionsFiltered[i] = { storagePositionName: storageName };
    }

    for (let j = 0; j < allStoragePositionsFiltered.length; j++) {
      allStoragePositionsFiltered[j].rollsReserved = [];
      for (let i = 0; i < allRRforToday.length; i++) {
        if (allStoragePositionsFiltered[j].storagePositionName === allRRforToday[i].roll.storagePosition) {
          allStoragePositionsFiltered[j].rollsReserved.push(allRRforToday[i]);
        }
      }
    }

    for (let i = 0; i < allStoragePositionsFiltered.length; i++) {
      allStoragePositionsFiltered[i].materials = [];
      for (let j = 0; j < allStoragePositionsFiltered[i].rollsReserved.length; j++) {
        const reservedMaterialId = allStoragePositionsFiltered[i].rollsReserved[j].material._id.toString();
        if (
          !allStoragePositionsFiltered[i].materials.some(
            material => material._id.toString() === reservedMaterialId
          )
        ) {
          const material = allMaterialsArrFiltered.find(
            mat => mat._id.toString() === reservedMaterialId
          );
          if (material) {
            allStoragePositionsFiltered[i].materials.push(material);
          }
        }
      }
    }

    for (let i = 0; i < allStoragePositionsFiltered.length; i++) {
      for (let j = 0; j < allStoragePositionsFiltered[i].materials.length; j++) {
        allStoragePositionsFiltered[i].materials[j].rollsReserved = [];
      }
    }

    ////NIJE HTELO DA RADI DODAVANJE/BRISANJE/PUSHOVANJE ETC. u machines pa sam morao novu promenljivu napraviti!
    let listForView = jsonc.parse(jsonc.stringify(allStoragePositionsFiltered));

    for (let i = 0; i < listForView.length; i++) {
      for (let j = 0; j < listForView[i].materials.length; j++) {
        for (let k = 0; k < listForView[i].rollsReserved.length; k++) {
          if (listForView[i].materials[j]._id === listForView[i].rollsReserved[k].material._id) {
            listForView[i].materials[j].rollsReserved.push(listForView[i].rollsReserved[k]);
          }
        }
      }
    }

    return listForView;
  };

  const listForView = filteringDataForStorekeeperView();

  useEffect(() => {
    dataa();
  }, []);

  const createPdfForStorekepeer = async () => {
    const res = await Axios.post(
      `${SERVER_URL}/production-process-day-storekeeper-createPdf`,
      { listForView, day: query.day },
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}`, 'Content-Type': 'application/json' },
      },
    );

    if (res.data.filename) {
      const pdfResponse = await Axios.get(
        `${SERVER_URL}/get-storekeeper-view-pdffilename?filename=${res.data.filename}`,
        {
          responseType: 'arraybuffer',
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      const pathArr = res.data.filename.split('\\');
      const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
      let a = document.createElement('a');
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/production-process'>
          <Button type='primary'>MESEČNI PLAN</Button>
        </Link>
        {currentuser.data.role.some((r) => ['superAdmin'].includes(r)) && (
          <>
            <Link to={`/admin/production-process-day?day=${query.day}&machine=${query.machine}`}>
              <Button style={{ marginLeft: 10, width: 230 }} type='primary'>
                Regularni pregled
              </Button>
            </Link>
          </>
        )}
        <Button
          style={{ marginLeft: 10, width: 230 }}
          icon={<PrinterOutlined />}
          type='secondary'
          onClick={createPdfForStorekepeer}
        >
          Štampanje pregleda
        </Button>
      </div>
      <div style={{ display: 'flex' }}>
        <DatePicker
          defaultValue={moment(day)}
          format={'YYYY-MM-DD'}
          picker='day'
          onChange={(value) => {
            setDate(value?.format('YYYY-MM-DD'));
            setDateChange(value?.format('YYYY-MM-DD'));
            history.push(
              `/admin/production-process-day-storekeeper?day=${value?.format(
                'YYYY-MM-DD',
              )}&machine=${sessionStorage.getItem('selectedMachine', value)}`,
            );
          }}
        />
      </div>

      <div className='panel-body'>
        {data?.length > 0 ? (
          <div style={{ fontSize: '20px', backgroundColor: 'white', borderRight: '1px' }}>
            {listForView &&
              listForView.length > 0 &&
              listForView.map((list, index) => (
                <>
                  <p
                    style={{
                      fontSize: '25px',
                      backgroundColor: '#006998',
                      color: 'white',
                      padding: '5px',
                      margin: '5px 0px 0px 0px',
                    }}
                  >
                    Pozicija u magacinu: <strong>{list.storagePositionName}</strong>
                  </p>
                  {list &&
                    list.materials &&
                    list.materials.length > 0 &&
                    list.materials.map((material, index) => (
                      <>
                        <div style={{ padding: '4px', backgroundColor: '#d9d9d9', color: 'black' }}>
                          Material: {material.code} - {material.name}
                        </div>
                        {material.rollsReserved &&
                          material.rollsReserved.map((item, index) => (
                            <>
                              <div
                                style={{
                                  fontSize: '15px',
                                  padding: '8px 0px 8px 4px',
                                  width: '100%',
                                  backgroundColor: '#e6e6e6',
                                  color: 'black',
                                  display: 'flex',
                                }}
                              >
                                Rolna: {item.roll.rollID} | Rezervisana količina materijala: {item.materialQuantity} |
                                Na radnom nalogu: {item.workOrder.code}
                                {item.roll.storagePosition ? ` | Mašina: ${item.workOrder.machine.name}` : ''}
                                {item.roll.status.includes('Čeka') ? (
                                  <Badge
                                    className='ant-badge-storekeeper-error'
                                    status='error'
                                    style={{ marginLeft: 'auto', marginRight: '10px' }}
                                    text={item.roll.status}
                                  />
                                ) : (
                                  <Badge
                                    className='ant-badge-storekeeper-success'
                                    status='success'
                                    style={{ marginLeft: 'auto', marginRight: '10px' }}
                                    text={item.roll.status}
                                  />
                                )}
                              </div>
                            </>
                          ))}
                      </>
                    ))}
                </>
              ))}
          </div>
        ) : (
          <div style={{ fontSize: 16 }}>Nema proizvodnih procesa za izabrani datum.</div>
        )}
      </div>
    </div>
  );
};

export default ProductionProcessDayStorekeeper;
