import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input, Button } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  CopyOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';

const WorkOrderTable = ({
  data,
  deleteHandler,
  columnKeys,
  title,
  editPath,
  viewPath,
  copyHandler,
  createPdf,
  value,
}) => {
  const user = useContext(UserContext);
  const [totalTimeKreiran, setTotalTimeKreiran] = useState();
  const [totalTimePriprema, setTotalTimePriprema] = useState();
  const [totalTimeOdstampan, setTotalTimeOdstampan] = useState();

  const [productionProcesses, fetchProductionProcesses] = useAxios('', [], user.data.token, 'get');
  let searchInput;

  useEffect(() => {
    fetchProductionProcesses(`${SERVER_URL}/production-process`, []);
  }, [fetchProductionProcesses]);
  let compareProdProcesses;

  if (productionProcesses && productionProcesses.data.count > 0) {
    compareProdProcesses = productionProcesses.data.items.map((item) => {
      return item.workOrder[0]?._id;
    });
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      let filter = localStorage.getItem('filter') && JSON.parse(localStorage.getItem('filter'));
      if (filter) {
        for (const property in filter) {
          if (dataIndex === property) {
            if (Array.isArray(selectedKeys) && selectedKeys.length === 0 && selectedKeys[0] !== filter[property]) {
              setSelectedKeys([`${filter[property]}`]);
              confirm();
            }
          }
        }
      }

      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : '')}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex, setSelectedKeys)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      let searchFilter = [];
      searchFilter = value.split(' ');
      const filterRecord = Array.isArray(dataIndex)
        ? record[dataIndex[0]][dataIndex[1]]?.toLowerCase()
        : record[dataIndex]?.toLowerCase();
      if (!filterRecord || !searchFilter) {
        return false;
      }
      return searchFilter.every((value) => filterRecord.includes(value.toLowerCase()));
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    let filter = localStorage.getItem('filter') ? JSON.parse(localStorage.getItem('filter')) : {};
    filter[`${dataIndex}`] = selectedKeys[0];
    localStorage.setItem('filter', JSON.stringify(filter));
    confirm();
  };

  const handleReset = (clearFilters, dataIndex, setSelectedKeys) => {
    let filter = localStorage.getItem('filter') && JSON.parse(localStorage.getItem('filter'));
    if (filter) {
      for (const property in filter) {
        if (dataIndex === property) {
          setSelectedKeys([]);
          delete filter[property];
          clearFilters();
        }
      }
    }
    localStorage.setItem('filter', JSON.stringify(filter));
  };

  const columns = columnKeys.map((item) => ({
    key: item?.originalName,
    title: item?.alternativeName?.toUpperCase(),
    dataIndex: ['title', 'category'].includes(item?.originalName)
      ? [item?.originalName, user?.language ? user?.language.selected.code : '']
      : item?.originalName,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item?.originalName)
        ? [item?.originalName, user?.language ? user?.language.selected.code : '']
        : item?.originalName,
    ),
  }));

  columns.splice(1, 0, {
    title: 'Naziv kupca',
    ...getColumnSearchProps('companyName'),
    render: (text, record) => {
      if (record && record.clientId && record.clientId.name) {
        return record.clientId.name;
      } else if (record && record.companyName) {
        return record.companyName;
      }else{
        return ""
      }
    },
  });

  columns.push({
    title: 'Status overe',
    ...getColumnSearchProps('workOrderAuthenticationStatus'),
    render: (text, record) => {
      if (record.workOrderAuthenticationStatus) {
        return record.workOrderAuthenticationStatus;
      } else {
        return ' Postojeća ';
      }
    },
  });

  columns.push({
    title: 'Opcije',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {(record.workOrderStatus === 'kreiran' ||
          record.workOrderStatus === 'čeka kreiranje' ||
          record.workOrderStatus === 'čeka materijal' ||
          record.workOrderStatus === 'kreiran-priprema') &&
          user.data.role.some((r) => ['superAdmin', 'admin', 'preparer'].includes(r)) && (
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Link to={editPath + record._id}>
                <EditOutlined
                  title={`Izmeni ${title.toLowerCase()}`}
                  style={{ textDecoration: 'none', color: 'black' }}
                />
              </Link>
            </div>
          )}

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={viewPath + record._id}>
              <EyeOutlined
                title={`Pregledaj ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        )}

        {record.workOrderStatus !== 'zatvoren' &&
          record.workOrderStatus !== 'Odštampan' &&
          record.workOrderStatus !== 'realizacija' &&
          record.workOrderStatus !== 'priprema' &&
          user.data.role.some((r) => ['superAdmin', 'admin'].includes(r)) && (
            <div style={{ margin: '2px', padding: '4px' }}>
              <Popconfirm
                // disabled={record._id === user.data.id}
                placement='left'
                title={`Ovo će obrisati ${title.toLowerCase()}`}
                onConfirm={() => {
                  deleteHandler(record._id);
                }}
                okText='Ok'
                cancelText='Cancel'
              >
                <DeleteOutlined
                  style={{ pointerEvents: compareProdProcesses?.includes(record._id) && 'none' }}
                  title={`Obriši ${title.toLowerCase()}`}
                />
              </Popconfirm>
            </div>
          )}

        {user.data.role.some((r) => ['superAdmin', 'admin'].includes(r)) && value !== 'npp' && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <CopyOutlined
              title={`Kopiraj ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => {
                copyHandler(record._id);
              }}
            />
          </div>
        )}

        {value !== 'npp' && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <FilePdfOutlined title='PDF' onClick={() => createPdf(record._id)} />
          </div>
        )}
      </div>
    ),
  });

  function onChange(page) {
    page && localStorage.setItem('pageNumber', page);
    localStorage.getItem('pageNumber') &&
      parseInt(localStorage.getItem('pageNumber')) === 1 &&
      localStorage.removeItem('pageNumber');
  }

  if (localStorage.getItem('url') && localStorage.getItem('url') !== window.location.href) {
    localStorage.removeItem('pageNumber');
    localStorage.removeItem('filter');
    localStorage.setItem('url', window.location.href);
  } else {
    localStorage.setItem('url', window.location.href);
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    ///racunanje ukupnog vremena za sve radne naloge koji nisu zatvoreni
    let timeKreiran = 0;
    let timePriprema = 0;
    let timeOdstampan = 0;

    if (extra && extra.currentDataSource) {
      extra.currentDataSource.forEach((element) => {
        if (element.workOrderStatus === 'priprema') {
          timePriprema += parseFloat(element.productionTime);
        } else if (element.workOrderStatus === 'Odštampan') {
          timeOdstampan += parseFloat(element.productionTime);
        } else if (element.workOrderStatus === 'kreiran') {
          timeKreiran += parseFloat(element.productionTime);
        }
      });
    } else {
      data.forEach((element) => {
        if (element.workOrderStatus === 'priprema') {
          timePriprema += parseFloat(element.productionTime);
        } else if (element.workOrderStatus === 'Odštampan') {
          timeOdstampan += parseFloat(element.productionTime);
        } else if (element.workOrderStatus === 'kreiran') {
          timeKreiran += parseFloat(element.productionTime);
        }
      });
    }

    setTotalTimeKreiran(timeKreiran);
    setTotalTimePriprema(timePriprema);
    setTotalTimeOdstampan(timeOdstampan);
  };

  useEffect(() => {
    handleTableChange();
  }, []);

  return (
    <div>
      <div
        style={{
          display: 'flex',
        }}
      >
        <p
          style={{
            // marginLeft: '10px',
            background: '#2574ab',
            color: 'white',
            width: 'fit-content',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '10px',
            paddingBottom: '10px',
            marginBottom: '10px',
            border: '1px solid grey',
          }}
        >
          <strong>Kreirani:</strong> {totalTimeKreiran}h {'  |  '}
          <strong>Na planu:</strong> {totalTimePriprema}h {'  | '}
          <strong>Odštampani:</strong> {totalTimeOdstampan}h
        </p>
      </div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        rowClassName={(record, index) => (record.workOrderStatus === 'zatvoren' ? 'darkRow' : 'lightRow')}
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
          onChange: onChange,

          defaultCurrent: localStorage.getItem('pageNumber') ? parseInt(localStorage.getItem('pageNumber')) : 1,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default WorkOrderTable;
